import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["content"]

    connect() {
        console.log("Dropdown toggler controller connected")
    }

    toggle(event) {
        let elementToShow = $(event.currentTarget).find(":selected").data("toggle")

        this.contentTargets.forEach((element, index) => {
            let elementId = `#${element.id}`
            element.classList.toggle("hidden", elementId != elementToShow)
        })
    }
}
