import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    link(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        const method = e.currentTarget.dataset.method || 'GET'

        fetch(e.currentTarget.href, {
            method: method,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
            })
    }

    form(e) {
        e.preventDefault()
        e.stopImmediatePropagation()

        let form = e.currentTarget.form

        return fetch(form.action, {
            method: form.method,
            body: $(form).serialize(),
            credentials: "same-origin",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/x-www-form-urlencoded",
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
            })
    }

  formTask(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    let form = e.currentTarget.form

    return fetch(form.action, {
      method: form.method,
      body: $(form).serialize(),
      credentials: "same-origin",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded",
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
        .then(response => response.text())
        .then(html => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");

          const modal = doc.querySelector('.task-management-modal');
          const table = doc.querySelector('.tables-wrapper')

          if (modal && table) {
              const newContentModal = modal.innerHTML;
              const newContentTable = table.innerHTML;
              if(newContentModal)
                document.querySelector('.task-management-modal').innerHTML = newContentModal;
              if(newContentTable)
                document.querySelector('.tables-wrapper').innerHTML = newContentTable;
              setSortableValues();
              setPredefinedFunctionalities();
          }

          let event = new CustomEvent('taskFormLoaded');
          document.dispatchEvent(event);
        });
  }

  setSortableValues() {
    $(".sortable").sortable({
      axis: "y",
      items: ".sortable-item",
      handle: ".sortable-handle",
      update: function(event, ui) {
        var sortedData = $(this).sortable('serialize');
        $.post($(this).data("sortable-url"), sortedData);
      }
    }).disableSelection();
  }

}
