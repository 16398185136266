import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "search",
        "results"
    ]

    connect() {
        console.log("Search controller connected")
        // Use lastSent and lastReceived to ignore responses that arrive out-of-order.
        this.lastSent = 0;
        this.lastReceived = 0;
    }

    disconnect() {
        this.stopPrevious()
    }

    search() {
        let query = this.searchTarget.value
        // console.log("Starting search for:", query)

        this.debounce(query)
    }

    concurrentSearch() {
        let query = this.searchTarget.value

        this.fetchResults(query)

    }

    debounce(query) {
        let delay = parseInt(this.data.get("debounce")) || 400
        this.stopPrevious()

        // console.log(`Starting search debounce with delay of ${delay}`)
        this.debouncer = setInterval((query) => {
            this.fetchResults(query)
            this.stopPrevious()
        }, delay)
    }

    stopPrevious() {
        if (this.debouncer) {
            // console.log("Stopping search debounce")
            clearInterval(this.debouncer)
        }
    }

    fetchResults(query) {
        // console.log("Searching for: ", this.searchTarget.value)
        let encodedQuery = encodeURIComponent(this.searchTarget.value)
        // let encodedAuthenticityToken = this.authenticityToken()
        let url = `${this.data.get("url")}?query=${encodedQuery}`
        // console.log(this.data)
        // console.log("At this URL: ", url)

        // The responses don't always arrive in the same order we sent them,
        // which causes the search results to "revert" to something you asked for before.
        // We use currentFetch to ignore all but the most-recently-sent request.
        const thisFetch = this.lastSent += 1;

        fetch(url, {
            credentials: "same-origin",
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                // console.log("Got search results", response)
                return response
            })
            .then(response => response.text())
            .then(html => {
                if (thisFetch <= this.lastReceived) return;
                this.lastReceived = thisFetch;

                // console.log("Adding search results to DOM", html)
                this.resultsTarget.innerHTML = html
                // Raise an event so that other things can respond,
                // e.g. toggle needs to set the new elements to the same show/hide as the existing ones.
                window.dispatchEvent(new CustomEvent("searched"));
            })
    }
}
