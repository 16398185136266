import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    /** On start */
    connect() {
        console.log("Scroll controller connected");

        // Add event listener to save scroll position before page reload
        window.addEventListener("beforeunload", this.saveScrollPosition);

        // Reset the scroll position to saved value
        this.resetScroll();

        // Add event listeners for paginator links
        this.addPaginationListeners();
    }

    /** On stop */
    disconnect() {
        // Remove event listener to avoid memory leaks
        window.removeEventListener("beforeunload", this.saveScrollPosition);
        // Remove event listeners for paginator links
        this.removePaginationListeners();
    }

    /** Save the current scroll position */
    saveScrollPosition() {
        const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
        localStorage.setItem("scrollPosition", scrollPosition);
    }

    /** Reset the scroll position to the saved value */
    resetScroll() {
        const savedPosition = localStorage.getItem("scrollPosition");
        if (savedPosition !== null) {
            document.documentElement.scrollTop = savedPosition;
            document.body.scrollTop = savedPosition; // For Safari
        }
    }

    /** Add event listeners to paginator links */
    addPaginationListeners() {
        const paginatorLinks = document.querySelectorAll(".pagination > li > a");
        paginatorLinks.forEach(link => {
            link.addEventListener("click", this.saveScrollPosition);
        });
    }

    /** Remove event listeners from paginator links */
    removePaginationListeners() {
        const paginatorLinks = document.querySelectorAll(".pagination > li > a");
        paginatorLinks.forEach(link => {
            link.removeEventListener("click", this.saveScrollPosition);
        });
    }
}
