import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.convertToTimeago()
    }

    convertToTimeago() {
        var midnightToday = new Date().setHours(0, 0, 0, 0);
        var midnightYesterday = midnightToday - 24 * 60 * 60 * 1000;
        var createdAt = this.dateTime().getTime();

        if (createdAt > midnightToday) {
            if (this.element.classList.contains("timeago-header")) {
                this.element.innerHTML = "Today"
            } else {
                this.element.innerHTML = this.formatTime()
            }
        } else if (midnightYesterday < createdAt && createdAt < midnightToday) {
            this.element.innerHTML = "Yesterday"
        } else {
            this.element.innerHTML = this.formatDate()
        }
    }

    iso8601() {
        return this.element.dataset.iso8601
    }

    dateTime() {
        return new Date(this.iso8601())
    }

    formatTime() {
        let hours = this.dateTime().getHours()
        let minutes = this.dateTime().getMinutes()
        let ampm = hours >= 12 ? "pm" : "am"

        if (hours > 12) {
            hours = hours - 12
        }

        minutes = minutes < 10 ? `0${minutes}` : minutes

        return `${hours}:${minutes} ${ampm}`
    };

    formatDate() {
        var month = this.dateTime().getMonth() + 1
        var day = this.dateTime().getDate()
        var year = this.dateTime().getFullYear().toString().substring(2)

        return `${month}/${day}/${year}`
    };

}
