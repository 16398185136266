import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "detailsContainer",
    ]

    connect() {
        console.log("Sort controller connected")
    }

    // NOTE: Include sort URL as a stimulus value in a 'data-sort-url' data attribute
    // NOTE: Include any custom options that need to be sent to the controller in an "options" data attribute as JSON
    sortColumn() {
        const options = this.data.get('options') || '{}'
        const element = event.currentTarget
        const column = element.dataset.column
        const direction = element.dataset.direction
        const urlPath = this.data.get('url')

        if (!urlPath || !column || !direction) {
            $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to sort successfully</div>`)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            return
        }

        let url = `${urlPath}${urlPath.indexOf("?") >= 0 ? "&" : "?"}` + new URLSearchParams({
            options: options,
            sort_by: column,
            sort_direction: direction,
        })

        return fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text()
            })
            .then(html => {
                this.detailsContainerTarget.innerHTML = html;
                // popovers need to be re-initialized:
                $(this.detailsContainerTarget).find('[data-toggle="popover"]').popover({
                    html: true,
                    content: function () {
                        target = $(this).next('.popover');
                        return $(target).first().children().clone();
                    }
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                $(".alert-container .col-xs-12").html(`<div class="alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert">×</button>Unable to sort successfully</div>`)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }
}
