import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["content", "button"]

    connect() {
        console.log("Button toggler controller connected")
    }

    toggle(event) {
        event.preventDefault()

        let url = event.currentTarget.getAttribute("href")
        this.fetchContent(url)
        this.activateButton(event.currentTarget)
    }

    fetchContent(url) {
        console.log("Fetching content at this URL: ", url)

        fetch(url, {
            credentials: "same-origin"
        })
            .then(response => {
                console.log("Got content", response)
                return response
            })
            .then(response => response.text())
            .then(html => {
                console.log("Adding search results to DOM", html)
                this.contentTarget.innerHTML = html
            })
    }

    activateButton(button) {
        this.buttonTargets.forEach((element, index) => {
            element.classList.toggle("active", element === button)
        })
    }
}
