import {Controller} from "@hotwired/stimulus"

/**
 * Adds/remove a class from its targets.
 *
 * This is mostly useful to hide/show things but lets you use whatever class you like
 * (based on data-toggle-class).
 *
 * You can call reapply if the targets change,
 * and we'll add/remove the class based on the current state.
 *
 * We assume the DOM starts with the class.
 * (We could parameterize that though, and maybe also enforce it in connect().)
 */
export default class extends Controller {
    static targets = ["toggleable"];

    connect() {
        // Remember which state we're in
        // so that if targets get added later, we can set them correctly.
        console.log("Toggle controller connected");
        this.hasClass = true;
    }

    toggle(ev) {
        ev.preventDefault();
        this.hasClass = !this.hasClass;
        this.toggleableTargets.forEach(t => t.classList.toggle(this.data.get("class")));
    }

    // If a product search happens,
    // we need to set the results to the same hide/show as the other rows.
    reapply(ev) {
        if (this.hasClass) {
            this.toggleableTargets.forEach(t => t.classList.add(this.data.get("class")));
        } else {
            this.toggleableTargets.forEach(t => t.classList.remove(this.data.get("class")));
        }
    }

}
