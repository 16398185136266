import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Dropdown Replacer controller connected")
    }

    replace(event) {
        console.log("Dropdown changed")
        let dropdown = event.currentTarget
        let url = dropdown.options[dropdown.selectedIndex].dataset.url
        let target = document.getElementById(this.data.get("target"))

        fetch(url, {
            credentials: "same-origin",
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                console.log("Got replacement HTML response", response)
                return response
            })
            .then(response => response.text())
            .then(html => {
                console.log("Adding HTML frament to DOM", html)
                console.log(target)
                target.innerHTML = html
            })
    }
}
