import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["display", "errors", "input"];

    connect() {
        // We have to jump through some hoops here because of how the popover works.
        // When you hide it, it resets its DOM to the original HTML.
        // So we have to update the <input> tag *after* is has been closed.
        $(this.displayTarget).on("hidden.bs.popover", () => {
            const v = $(this.displayTarget).text().trim();
            $(this.inputTarget).attr('value', v);
        });
    }

    onSuccess(event) {
        this.errorsTarget.innerHTML = "";
        this.displayTarget.innerHTML = event.detail.data;
        $(this.displayTarget).popover('hide');
    }

    onError(event) {
        this.errorsTarget.innerHTML = event.detail.data;
    }

}
