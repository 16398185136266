import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "checkbox",
        "values",
        "button"
    ]

    connect() {
        console.log("Multicheck controller connected")
    }

    checkedValues() {
        let checkedValues = []

        this.checkboxTargets.forEach((element, id) => {
            // console.log("Checkbox value:", element.value)
            // console.log("Checkbox checked: ", element.checked)
            if (element.checked) {
                checkedValues.push(element.value)
            }
        })

        return checkedValues
    }

    setValues() {
        this.valuesTarget.value = this.checkedValues().toString()
    }

    setButtonState() {
        this.buttonTarget.disabled = (this.checkedValues().length === 0)
    }

    check(event) {
        this.setValues()
        this.setButtonState()
    }

    checkAll(event) {
        this.checkboxTargets.forEach((element, id) => {
            element.checked = true
        })

        this.setValues()
        this.setButtonState()
    }

    uncheckAll(event) {
        this.checkboxTargets.forEach((element, id) => {
            element.checked = false
        })

        this.setValues()
        this.setButtonState()
    }
}
