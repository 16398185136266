import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "refresh",
    ]

    connect() {
        let interval = this.element.dataset.interval || 5

        setTimeout(this.fetch.bind(this), interval * 1000);
    }

    fetch() {
        fetch(this.element.dataset.url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html
            })
        }
    }

