import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "file",
        "id"
    ]

    connect() {
        console.log("Upload controller connected")
    }

    upload() {
        // console.log("Uploading file")

        let reader = new FileReader()

        reader.addEventListener("load", () => {
            console.log("File field done loading file")
            this.transmitFile(reader.result)
        }, false)

        reader.readAsDataURL(this.fileTarget.files[0])
    }

    transmitFile(file) {
        // console.log("Transmitting file")

        let url = this.data.get("url")
        let data = {file: file}

        fetch(url, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                // If the upload failed, show the user why:
                if (json.errors) {
                    var $fg = $(this.fileTarget).closest(".form-group");
                    $fg.find(".help-block").remove();
                    $fg.addClass("has-error");
                    var $err = $("<span class='help-block'></span>");
                    $err.text(json.errors);
                    $fg.append($err);
                } else {
                    $(this.fileTarget).closest(".form-group").removeClass("has-error").find(".help-block").remove();
                    this.idTarget.value = json.id
                }
            })
    }
}
